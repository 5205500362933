.package-card-wrapper {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	min-height: 207px;
	border: 1px solid #c9c9c9;
	border-radius: 12px;
	margin-bottom: 20px;
	@media (max-width: 992px) {
		position: relative;
	}
	.package-card--image {
		width: 213px;
		height: 188px;
		margin: 11px;
		align-self: flex-start;
		position: relative;
		.placeholder-image {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 70px;
			padding: 12px;
			background-color: #e3e3e3;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			border-radius: 8px;
			svg {
				width: 100%;
				height: 100%;
			}
		}
		img {
			object-fit: cover;
		}
		.package-fav {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 8px;
			top: 8px;
			width: 38px;
			height: 38px;
			background: #ffffff4d;
			border-radius: 50%;
			> span {
				// @include font(20px, 600, #fff);
				font-size: 20px;
				color: #fff;
				opacity: 0.64;
				cursor: pointer;
			}
		}
		.package-fav__done {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 8px;
			top: 8px;
			width: 38px;
			height: 38px;
			border-radius: 50%;
			background-color: #ed6d00;
			opacity: 1;
			> span {
				font-size: 20px;
				cursor: pointer;
				color: #fff;
				border-color: #fff;
				opacity: 1;
			}
		}
		@media only screen and (max-width: 992px) {
			min-height: initial;
			min-width: initial;
			align-self: center;
			margin-top: 0;
			flex: 1;
		}
	}
	.package-card--content {
		display: flex;
		flex-direction: column;
		flex: 1;
		gap: 8px;
		padding: 11px;
		.package-num-holder {
			font-size: 13px;
			color: #8d8d8d;
		}
		.package-content__title {
			p,
			a {
				margin-bottom: 10px;
				font-size: 20px !important;
				font-weight: 500;
				color: #2b2f33;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-decoration: none;
				word-break: break-word;
				line-height: initial;
				@media (max-width: 992px) {
					padding-inline-end: 20px;
				}
			}
		}
		.package-content__location {
			display: flex;
			align-items: center;
			gap: 8px;
			.package-places {
				display: flex;
				align-items: center;
				gap: 8px;
				font-weight: 500;
				color: #484848;
				svg {
					font-size: 24px;
					color: #a7a7a7;
				}
			}
			.package--types {
				font-size: 12px;
				font-weight: 500;
				margin-inline-start: 5px;
				color: #0a7db9;
			}
		}
		.package-content__desc {
			font-size: 12px;
			font-weight: 500;
			color: #8d8d8d;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			word-break: break-word;
			* {
				line-height: 1.5;
			}
		}
		.package-content__date {
			gap: 8px;
			> div {
				display: flex;
				align-items: center;
				gap: 8px;
			}
			.location__everday {
				width: 85px;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 24px;
				// background-color: #f0f2ff;
				color: #484848;
				font-size: 14px;
				font-weight: 500;
			}
			.location__availability {
				color: #484848;
				font-size: 12px;
				font-weight: 500;
			}

			svg {
				font-size: 24px;
				color: #a7a7a7;
			}
		}
		.package-data-availability {
			font-size: 14px;
			font-weight: 500;
			color: #484848;
		}
	}
	.package-card--summry {
		min-width: 220px;
		// height: 208px;
		border-inline-start: 1px solid #c9c9c9;
		padding: 11px;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		align-self: stretch;
		flex-wrap: wrap;
		row-gap: 20px;
		flex-direction: column;
		@media (max-width: 767.98px) {
			width: 100% !important;
			align-items: start;
			justify-content: space-between;
			align-self: stretch;
		}
		.package-card--seats {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			font-size: 12px;
			font-weight: 500;
			color: #171717;
			gap: 8px;
			@media (max-width: 992px) {
				position: absolute;
				top: 180px;
				right: 12px;
				&:lang(ar) {
					right: auto;
					left: 12px;
				}
			}
			@media (max-width: 768px) {
				top: 160px;
			}
			.package-card--menu {
				font-size: 24px;
				font-weight: 800;
				color: #b9b9b9;
				cursor: pointer;
				svg {
					stroke: #b9b9b9;
					stroke-width: 50px;
				}
			}
		}
		.package-card--stars {
			width: 60px;
			height: 38px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 4px;
			font-size: 17px;
			font-weight: 600;
			background-color: #ffa700;
			color: #fff;
			border-radius: 12px;
		}
		.package-card--duration {
			font-size: 14px;
			font-weight: 500;
			color: #484848;
			display: flex;
			align-items: center;
			gap: 5px;
			// &:lang(ar) {
			//     direction: ltr;
			// }
		}
		.package-card--expired {
			font-size: 18px;
			font-weight: 500;
			text-transform: capitalize;
			color: #faad14;
		}
		.package-card--price {
			display: flex;
			align-items: flex-end;
			justify-content: flex-start;
			flex-wrap: wrap;
			flex-direction: column;
			@media (max-width: 767.98px) {
				flex-direction: row;
				align-items: center;
			}
			gap: 5px;
			> span {
				display: flex;
				align-items: center;
				font-size: 20px;
				font-weight: bold;
				// color: #542ef9;
				min-height: 33px;
				gap: 8px;
				.price-lable {
					color: #6f6f6f;
					font-size: 10px;
					font-weight: normal;
				}
			}
			> p {
				font-size: 12px;
				font-weight: 400;
				color: #7b7b7b;
			}
		}
		.switch-holder {
			display: flex;
			gap: 10px;
			align-items: center;
			button {
				background-color: #57a721 !important;
				&[aria-checked="false"] {
					background-color: #ff422f !important;
				}
			}
			.switch-text {
				font-size: 14px !important;
				font-weight: 500;
				min-width: 88px;
				text-align: end;
				&.text-red {
					color: #ff422f;
				}
				&.text-green {
					color: #57a721;
				}
			}
		}
	}
	.package-card--favourite {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 5px;
		button {
			padding: 6px;
			border-radius: 8px;
		}
		.btn-favourite-remove {
			background: #f2f2f0;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			gap: 2px;
		}
	}

	@media (max-width: 767.98px) {
		padding: 0px !important;
	}
	@media only screen and (max-width: 992px) {
		flex-wrap: wrap;
		padding-top: 8px;
		padding-bottom: 8px;
		.package-card--image {
			@media (max-width: 767.98px) {
				margin: 0px !important;
			}
			order: 1;
			width: auto;
			min-width: 100px;
			height: 144px;
		}
		.package-card--content {
			order: 3;
			flex: auto;
			width: 100%;
			.package-content__desc {
				display: none;
			}
			&:lang(ar) {
				width: 100%;
				flex: none;
			}
		}
		.package-card--summry {
			order: 2;
			@media (max-width: 767.98px) {
				order: 3;
			}
			width: fit-content;
			border: 0;
			min-height: initial;
			> button {
				display: none;
			}
			&:lang(ar) {
				flex: 1;
			}
		}
	}
}
.package-card--dropdown {
	ul {
		padding: 12px 23px;
		border-radius: 10px;
		li {
			height: 44px;
			span {
				svg {
					font-size: 24px;
				}
			}
		}
	}
}
