@import '../../public/assets/common/styleSheets/sass/abstracts/variables';

@mixin customPaddingLeftAndRight {
	padding-left: 24px !important;
	padding-right: 24px !important;
}

.PaxInfoDrawer {
	border-radius: 8px;
	:global {
		.ant-drawer-content-wrapper {
			width: 100% !important;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			overflow: hidden;
			height: auto !important;
		}
	}
	width: 100% !important;
	// border-radius: 11.5px !important;
	// border: 1px solid $primary;
	overflow: hidden;
	padding-bottom: 0;

	:global {
		.ant-space-item {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.ant-modal-content {
			padding: 24px 0;
		}
	}

	&__RoomGroupTitle {
		font-weight: 600;
		// @include customPaddingLeftAndRight();
	}

	&__Fields {
		// max-height: 631px;
		// width: 100vw;
		min-height: 300px;
		max-height: 70vh;
		@include customPaddingLeftAndRight();
		overflow: auto;
		overflow-x: hidden;
	}
	.PaxInfoDrawer__FieldWrapper {
		max-height: 0;
		animation: heightAnimation 100ms ease forwards;
		padding-top: 10px;
    margin-bottom: 10px !important;
	}
	@keyframes heightAnimation {
		0% {
			max-height: 100px;
		}

		20% {
			max-height: 150px;
		}
		50% {
			max-height: 200px;
		}
		70% {
			max-height: 250px;
		}
		90% {
			max-height: 350px;
		}
		100% {
			max-height: 400px;
		}
	}

	&__Field {
		padding: 20px 0 !important;
		border-bottom: 1px solid $lightt-grey;
		display: flex;
		flex-direction: column;
		gap: 10px;

		.PaxInfoDrawer__Field__CounterWrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
		}
	}

	&__TotalInfo {
		margin-top: 10px;
		&__Label {
			color: $grey;
		}

		&__Text {
			color: black;
			font-weight: 500;
		}
	}

	&__ChildWarning {
		margin-top: 10px;
		&__Title {
			font-size: 16px;
		}
		&__Warning {
			color: #1492e6;
			font-size: 12px;
		}
	}
	&__Actions {
		@include customPaddingLeftAndRight();
		display: flex;
		flex-direction: column;
		gap: 20px;
		justify-content: space-between;
		align-items: center;
		padding: 20px 0;
		// padding-bottom: 20px;
		background-color: white;
		width: 100%;

		&__AddBtn {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			cursor: pointer;
			// color: #532df8;
		}

		&__SaveBtn {
			// width: auto !important;
			height: 44px !important;
			// height: auto !important;
		}
	}

	&__RemoveBtn {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;

		&__Label {
			font-weight: bold;
			text-transform: capitalize;
		}
	}
	// &__InputWithLabel {
	// 	// :global {
	// 	// 	.ant-input-number .ant-input-number-input {
	// 	// 		padding-right: 65px;
	// 	// 	}
	// 	// }
	// }
	&__Input {
		width: 120px;

		&__Icon {
			font-size: 20px;
			// color: #532df8;
		}

		:global {
			.ant-input-suffix {
				gap: 10px;
			}
			.ant-input-number .ant-input-number-handler-down {
				border-block-start: none;
				left: 5%;
				top: 50%;
				transform: translateY(-50%);
				z-index: 10;
			}
			.ant-input-number .ant-input-number-handler-up {
				border-block-start: none;
				right: 5%;
				top: 50%;
				transform: translateY(-50%);
				z-index: 10;
			}
			.ant-input-number .ant-input-number-input {
				text-align: center;
			}
			.ant-input-number-handler-wrap .ant-input-number-handler {
				height: 100% !important;
				border-inline-start: none;
				@media only screen and (max-width: 768px) {
					display: block !important;
				}
			}
			.ant-input-number-handler {
				position: absolute;
				@media only screen and (max-width: 768px) {
					display: block !important;
				}
			}
			.ant-input-number-handler-wrap {
				display: block !important;
				opacity: 1 !important;
				position: static;
				z-index: 2;

				@media only screen and (max-width: 768px) {
					display: block !important;
				}
			}
			.ant-input-number-handler-up-disabled,
			.ant-input-number-handler-down-disabled {
				opacity: 0.3;
			}
		}

		&__Suffix {
			top: 2%;
			background: white;
			height: 96%;
			left: 45%;
			display: flex;
			color: #acb0b5;
			justify-content: center;
			// width: 47%;
			z-index: 1;
			align-items: center;
			position: absolute;
		}
	}
	.PaxInfoDrawer__Field__Childs {
		margin-top: 10px;
		// width: 100%;
	}
}
