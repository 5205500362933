.Drawer {
	border-radius: 8px;
	:global {
		.ant-drawer-body {
			padding: 0;
		}
		.ant-drawer-content-wrapper {
			width: 100% !important;
      position: fixed;
			max-width: 100vw;
			height: 85vh !important;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			overflow: hidden;
		}
	}
}
