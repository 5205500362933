@import "public/assets/common/styleSheets/sass/abstracts/_variables.scss";
@import "public/assets/common/styleSheets/sass/abstracts/_mixins.scss";
.AuthBox_Login {
	// // width: 520px;
	// max-width: 520px;
	// min-width: 500px;
	// min-height: 400px;
	// background: $white_color;
	// // box-shadow: 0px 6px 36px rgb(0 0 0 / 8%);
	// border-radius: 16px;
	// padding: 40px 50px;
	// // margin: 150px auto 0;
	&.modal_box {
		margin: 0;
	}
	@media (max-width: 992px) {
		width: 90%;
		padding: 40px 30px;
	}

	.LoginBox_Save {
		margin-top: 15px;
	}
	.LoginBox_SignUp {
		width: 100%;
		height: 17px;
		text-align: center;
		font-size: 12px;
		font-weight: 500;
		color: #838383;
		margin: 20px auto 40px;
		a {
			text-decoration: underline;
			font-size: 12px;
			font-weight: 500;
			cursor: pointer;
			margin-inline-start: 3px;
		}
	}
	.LoginBox_OtherSign {
		width: 100%;
		height: 10px;
		border-bottom: 1px solid #a7a7a7;
		text-align: center;
		margin-bottom: 32px;
		.LoginBox_OtherSign1_Span {
			color: #2b2f33;
			font-size: 14px;
			font-weight: 600;
			background-color: $white_color;
			padding: 0 10px;
		}
	}
	.LoginBox_Continue {
		height: 50px;
		border: 1px solid #a7a7a7;
		border-radius: 12px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0px 26px;
		cursor: pointer;
		.LoginBox_Continue_Text {
			height: 17px;
			text-align: center;
			font-size: 12px;
			font-weight: 600;
			color: $black-color;
		}
		& > svg {
			width: 26px !important;
			height: 26px !important;
		}
		.LoginBox_Continue_Extra {
			width: 26px;
			height: 26px;
		}
	}
}
