// Colors
$primary: #5d43f9;
$selected-bg: $primary;
$accent: $primary;
$secondary_text: #767676;
$white_color: #fff;
$primary_color: #5143f8;
$cool-green: #57a721;
$alert-red: #ff422f;
$vibrarnt-black: #171717;
$grey: #808080;
$lightt-grey: #bababa;
$action-coclor: #ffcb1c;
$secondary2-color: #2eb3f9;
$secondary-color: #8a4cf5;
$black-color: #000;

// variables
$sideBar_Mobile_Breakpoint: 992px;
$mobile-bp: 768px;

// others

$radius-primary: 8px;

:export {
    // Colors
    $primary: $primary;
    $kendo-button-theme-colors: $primary;
    $selected-bg: $primary;
    $kendo-button-selected-bg: $primary;
    $kendo-button-selected-border: $primary;
    $kendo-checkbox-checked-bg: $primary;
    $kendo-checkbox-checked-border: $primary;
    $kendo-checkbox-indeterminate-text: $primary;
    $kendo-checkbox-ripple-bg: $primary;
    $accent: $primary;
    $kendo-menu-popup-item-expanded-bg: $primary;
    $kendo-list-item-selected-bg: $primary;
    $kendo-radio-checked-bg: $primary;
    $kendo-radio-checked-border: $primary;
    $kendo-radio-ripple-bg: $primary;
    $kendo-switch-on-track-border: $primary;
    $kendo-switch-on-track-bg: $primary;
    $tabstrip-item-text: $primary;
    $kendo-treeview-item-selected-bg: $primary;
    $kendo-treeview-loadmore-text: $primary;
    $secondary_text: $secondary_text;
    $white_color: $white_color;
    $primary_color: $primary_color;
    $cool-green: $cool-green;
    $alert-red: $alert-red;
    $vibrarnt-black: $vibrarnt-black;
    $grey: $grey;
    $lightt-grey: $lightt-grey;
    $action-coclor: $action-coclor;
    $secondary2-color: $secondary2-color;
    $secondary-color: $secondary-color;
    $black-color: $black-color;

    // variables
    $sideBar_Mobile_Breakpoint: $sideBar_Mobile_Breakpoint;
}
